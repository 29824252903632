/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IStoryBlokAnnouncement,
  IStoryBlokAnnouncementBanner,
  IStoryBlokCookieBar,
  IStoryBlokFooter,
  IStoryBlokNewsletterSignUpModal,
  IStoryBlokSeo,
} from "apps/website/components/page/api.types";
import {
  IStoryBlokAnnouncementBannerProps,
} from "apps/website/components/storyblok/page/StoryBlokAnnouncementBanner";
import { IFooter } from "apps/website/components/page/Footer/Footer";
import {
  IHeaderLink,
  ILink,
} from "apps/website/components/base/Button/Button.map";
import {
  HEADER_LINKS_DEFAULT,
  HEADER_LINKS_BREEDER,
  IHeaderLinks,
  HEADER_LINKS_META,
} from "apps/website/components/page/Header/Header.map";
import { PublicConfig } from "apps/website/config/public";

import { makePageTags } from "../website/head";
import {
  getStoryBlokFooterGroup,
  getStoryBlokFooterGroups,
  getStoryBlokFooterNewsletter,
} from "../storyblok/page";
import { getStoryBlokPageWrapperLinks } from "../storyblok/links";
import { renderStoryBlokRichText } from "../storyblok/text";
import { ImageMax, getStoryBlokImage } from "../storyblok/image";

export const getHeadData = (
  seo: IStoryBlokSeo | undefined,
  hideFromSearch: boolean | undefined,
  pageLastBuilt: string | undefined,
  fallbackTags: IStoryBlokSeo | undefined,
) => ({
  seo: seo ? makePageTags(seo) : undefined,
  hideFromSearch: hideFromSearch || false,
  lastBuilt: pageLastBuilt,
  fallbackTags: fallbackTags ? makePageTags(fallbackTags) : undefined,
});

export const getAnnouncementBannerData = (
  pageAnnouncements: IStoryBlokAnnouncement[],
  defaultAnnouncements: IStoryBlokAnnouncementBanner,
): IStoryBlokAnnouncementBannerProps => ({
  pageAnnouncements,
  defaultAnnouncements,
});

export const getFooterData = (
  footer: IStoryBlokFooter,
  isMetaLandingPage?: boolean,
): IFooter => {
  const secondaryLinkGroups = getStoryBlokFooterGroups(footer.secondary_link_groups).map((group) => {
    // Override the start my trial link for the meta landing page
    if (isMetaLandingPage && group.title === "Try Fresh") {
      return {
        ...group,
        links: group.links.map((link) => {
          if (link.to === "https://join.katkin.com") {
            return {
              ...link,
              to: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/forms/fresh-food-new-user?discount_code=TRY20`,
            };
          }
          return link;
        }),
      };
    }
    return group;
  });

  return {
    newsletterSignUp: getStoryBlokFooterNewsletter(footer.newsletter_sign_up[0]),
    primaryLinkGroup: getStoryBlokFooterGroup(footer.primary_link_group[0]),
    secondaryLinkGroups,
    tertiaryLinks: getStoryBlokPageWrapperLinks(footer.tertiary_links).filter(
      (x) => !!x,
    ).map((y) => y as ILink),
  };
};

export const getCookieBannerData = (
  banner: IStoryBlokCookieBar,
) => ({
  body: renderStoryBlokRichText(banner.body),
  cta: { id: banner.cta[0]._uid, ...banner.cta[0] },
});

export const getNewsletterData = (
  newsletter: IStoryBlokNewsletterSignUpModal,
) => ({
  title: newsletter.title,
  completeTitle: newsletter.complete_title,
  body: newsletter.body,
  completeBody: newsletter.complete_body,
  buttonText: newsletter.button_text,
  theme: newsletter.theme,
  image: getStoryBlokImage(newsletter.image, true, ImageMax.CONTAINER_SM),
});

const mapLinksWithAssociatedDiscount = (links: IHeaderLink[], discountCode: string) => links.map((link) => ({ ...link, to: link.withAssociatedDiscount ? `${link.to}?discount_code=${discountCode}` : link.to }));

const getHeaderLinksWithAssociatedDiscount = (headerLinks: IHeaderLinks, discountCode: string | undefined) => {
  if (!discountCode) return headerLinks;
  return {
    primaryLinks: mapLinksWithAssociatedDiscount(headerLinks.primaryLinks, discountCode),
    secondaryLinks: mapLinksWithAssociatedDiscount(headerLinks.secondaryLinks, discountCode),
    authLinks: mapLinksWithAssociatedDiscount(headerLinks.authLinks, discountCode),
    guestLinks: mapLinksWithAssociatedDiscount(headerLinks.guestLinks, discountCode),
  };
};

const getHeaderLinksForStory = (story: any, associatedDiscount: string) => {
  if (story?.isMetaSignUpFunnelPage) {
    return getHeaderLinksWithAssociatedDiscount(HEADER_LINKS_META, associatedDiscount);
  }

  if (story?.main?.content?.header_navigation === "breeder") {
    return getHeaderLinksWithAssociatedDiscount(HEADER_LINKS_BREEDER, associatedDiscount);
  }

  return getHeaderLinksWithAssociatedDiscount(HEADER_LINKS_DEFAULT, associatedDiscount);
};

const getAnnouncementBannerIfEnabled = (story: any) => {
  if (!story?.main?.content?.announcements && !story?.announcementBanner) {
    return null;
  }
  return getAnnouncementBannerData(story.main?.content?.announcements, story.announcementBanner);
};

// @ts-ignore
export const getDefaultLayoutData = (story?: any, lastBuilt?: string, scripts?: HeaderScript[] = [ "trustpilot" ]): IPageLayout => { // eslint-disable-line
  const associatedDiscount = story?.main?.content?.associated_discount_code;

  return {
    headerLinks: getHeaderLinksForStory(story, associatedDiscount),
    head: story?.main && getHeadData(
      story.main.content.seo,
      story.main.content.hide_from_search_engines,
      lastBuilt,
      story.fallbackTags,
    ),
    scripts,
    announcementBanner: getAnnouncementBannerIfEnabled(story),
    footer: story?.footer && getFooterData(story.footer, story?.isMetaSignUpFunnelPage),
    cookieBanner: story?.cookieBar && getCookieBannerData(story.cookieBar),
    hideCookieBanner: story?.main?.content?.hide_cookie_bar,
    hideAnnouncementBanner: story?.main?.content?.hide_announcements || false,
    newsletterModal: story?.newsletterSignUpModal && getNewsletterData(story.newsletterSignUpModal),
    lastBuilt,
  };
};
